<template>
  <div class="company-root-container">
    <div class="company-header-container">
      <div class="company-header-left-container">
        <img @click="home" :src="icons.homeWhite" style="cursor: pointer" />
        <span class="company-header-title">企业用户</span>
      </div>
      <img @click="close" :src="icons.upArrow" class="project-arrow" />
    </div>
    <div class="company-main-container">
      <transition name="fade" mode="out-in">
        <router-view class="company-router-container" />
      </transition>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import * as icon from "../../common/icon";
import moment from "moment";
export default {
  components: {},
  data() {
    return {
      icons: icon,
    };
  },
  mounted(){

  },
  methods: {
    close() {
      this.$router.push({ name: "home" });
    },
    home() {
      if (this.$router.currentRoute.name == "company-list") {
        return;
      }
      this.$router.push({
        name: "company-list",
      });
    },
  },
};
</script>

<style scoped>
.company-root-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: #f7f7f7;
  overflow: hidden;
}
.company-header-container {
  background-color: #313f4a;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 50px;
  padding-left: 50px;
}
.company-header-left-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.company-arrow {
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.company-header-title {
  margin-left: 40px;
  color: #fff;
  font-size: 15px;
}
.company-main-container {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
}
.company-router-container {
  flex: 1;
  overflow: hidden;
}
</style>